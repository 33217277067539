.tableActions {
  position: sticky;

  /* bottom margin for mobile footer navigation */
  bottom: 56px;
}

@media screen and (min-width: 1025px) {
  .tableActions {
    bottom: 16px;
  }
}

.errorMessage {
  width: 100%;
  max-width: none;
}

$blue: #1285d0;
$orange: #fabb2f;
$green: #72c983;

.dashboard {
  .row {
    margin: 0 0 2em 0;
  }
}

.chart {
  background: #f9f7fb;
  display: flex;
  flex-direction: column;
  .legends {
    ul {
      padding-left: 0;
      display: flex;
      flex-wrap: wrap;
      margin: 1em;
      li {
        list-style: none;
        flex: 1 0 auto;
        min-width: 5em;
        padding-bottom: 0.25em;
        padding-right: 1em;
        span {
          color: #666;
          vertical-align: middle;
        }
        &:before {
          content: '';
          display: inline-block;
          width: 1em;
          height: 1em;
          background: currentColor;
          border-radius: 50%;
          margin-right: 0.5em;
          vertical-align: middle;
        }
      }
    }
  }
}

.line {
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  &.collapsed {
    height: auto;
  }
  h2 {
    margin: 1em 2em 0em;
  }
  svg {
    margin: 0 auto;
    display: block;
    text {
      font-size: 15px !important;
      font-weight: bold;
    }
  }
  .messageBox {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin: 1em 0 0;
  }
  .legends {
    margin: 0 2em;
  }
}

.icon {
  &.operation {
    color: #333;
  }
  &.material {
    color: #666;
  }
  &.fluid,
  &.high,
  &.orange {
    color: #fabb2f;
  }
  &.low,
  &.blue {
    color: #1285d0;
  }
  &.success {
    color: #72c983;
  }
  &.warning {
    color: #f00;
  }
}

.line-marker {
  foreignObject.iconContainer {
    transform: translate(-3px, -11px);
    width: 20px;
    height: 20px;
  }
  foreignObject.tooltipContainer {
    width: 240px;
    height: 300px;
    pointer-events: none;
    .tooltip {
      opacity: 0;
      transition: opacity 0.3s;
      background: white;
      padding: 0.75em 1em;
      border: 1px solid #bdbdbd;
      border-radius: 2px;
      margin: 5px;
      .label {
        margin-bottom: 0;
        font-size: 0.8rem;
        text-transform: uppercase;
      }
    }
  }
  a:hover + foreignObject .tooltip {
    opacity: 1;
  }
  line {
    stroke-width: 3;
    stroke: #2196f3;
  }
  &.action line {
    stroke: #00bcd4;
  }
  &.event {
    a {
      cursor: default;
    }
    line {
      stroke: #4caf50;
    }
  }
}

.waffle {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .waffle-box {
    margin: 0 2px 2px 0;
  }
  &.large .waffle-box {
    width: 100px;
    height: 100px;
  }
  &.small .waffle-box {
    width: 40px;
    height: 40px;
  }
}

.waffle-box {
  display: block;
  width: 40px;
  height: 40px;
  border: 2px solid #bbb;
  border-radius: 2px;
  background-color: lighten(#bbb, 20);
  position: relative;
  &.too-low,
  &.blue {
    border-color: $blue;
    background-color: lighten($blue, 20);
  }
  &.good,
  &.green {
    border-color: $green;
    background-color: lighten($green, 20);
  }
  &.too-high,
  &.bad,
  &.orange {
    border-color: $orange;
    background-color: lighten($orange, 20);
  }
  &.red {
    border-color: red;
    background-color: lighten(red, 20);
  }
  .tooltipContainer {
    z-index: 1000;
    border: 2px solid grey;
    background-color: white;
    border-radius: 2px;
    padding: 0.25em;
    position: absolute;
    left: calc(100% + 3px);
    top: -2px;
    min-width: 300px;
    box-shadow: 0 0px 5px 0px #3336;
    display: none;
    .date {
      font-weight: bold;
    }
    @media screen and (min-width: 520px) {
      display: block;
    }
    &:before {
      content: '';
      position: absolute;
      border: 5px solid #0000;
      border-right-color: grey;
      left: -12px;
    }
    &:after {
      content: '';
      position: absolute;
      border: 4px solid #0000;
      border-right-color: white;
      left: -8px;
      top: 5px;
      width: 5px;
      height: 5px;
    }
  }
  &.right .tooltipContainer {
    left: unset;
    right: calc(100% + 3px);
    &:before {
      border: 5px solid #0000;
      border-left-color: grey;
      left: unset;
      right: -12px;
    }
    &:after {
      border: 4px solid #0000;
      border-left-color: white;
      left: unset;
      right: -8px;
    }
  }
}

.is-clickable {
  cursor: pointer;
}

.metric-value {
  &.within-threshold {
    color: $green;
  }
  &.below-threshold {
    color: $blue;
  }
  &.above-threshold {
    color: $orange;
  }
}

.measurement-label {
  display: flex;
  font-size: 1rem;
  flex-direction: row;
  flex: 1;
  @media screen and (min-width: 520px) {
    margin-top: -0.2rem;
    flex-direction: column;
    flex: 1 0 120px;
  }
  .measurement-name {
    font-weight: bold;
    padding-right: 0.5em;
    &:after {
      content: ':';
      @media screen and (min-width: 520px) {
        content: '';
      }
    }
  }
  .measurement-value {
    padding-right: 0.5em;
  }
  .measurement-date {
    &:before {
      content: '(';
    }
    &:after {
      content: ')';
    }
    @media screen and (min-width: 520px) {
      &:before,
      &:after {
        content: '';
      }
    }
  }
}

.measurement-text-container,
.measurement-photo-container {
  display: block;
  margin: 0.5em 0 1em 0;
  .measurement-label {
    display: flex;
    font-size: 1rem;
    flex-direction: row;
    flex: 1;
    flex-wrap: wrap;
    .measurement-name {
      font-weight: bold;
      padding-right: 0.5em;
      &:after {
        content: '';
      }
    }
    .measurement-date {
      &:before,
      &:after {
        content: '';
      }
    }
    .measurement-value {
      flex: 1 0 100%;
    }
  }
}

.measurement-minmax-container {
  display: flex;
  width: 100%;
  height: 100px;
  flex-direction: column;
  @media screen and (min-width: 520px) {
    flex-direction: row;
  }
  div:last-child > div > svg  {
    width: 100% !important;
  }
  .empty-bullet-chart {
    width: 100%;
    text-align: center;
    padding-top: 2em;
    background: #f5f5f5;
    margin: 0 0 0.5em 0.5em;
  }
}

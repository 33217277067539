// Uppy Overrides

// These overrides are for an Uppy config setting of `height: 200`
// https://uppy.io/docs/dashboard/#height-550

// Make thumbnails in mobile view bigger
.uppy-Dashboard:not(.uppy-size--md) .uppy-DashboardItem-preview {
  width: 140px;
  height: 140px;
}

// Make thumbnails in the medium view smaller (to not overflow the 200 height)
.uppy-size--md .uppy-DashboardItem-preview {
  height: 120px;
}

// Make thumbnails in the large and xl views smaller (to not overflow the 200 height)
.uppy-size--xl .uppy-DashboardItem,
.uppy-size--lg .uppy-DashboardItem {
  height: unset;
}

// Hide thumbnail filename and size
.uppy-DashboardItem-name,
.uppy-DashboardItem-status {
  display: none;
}

// Prevents scrolling in ul.uppy-Dashboard-files
// This needs to be removed if we ever allow more than 1 photo
.uppy-Dashboard-files {
  min-height: 180px;
}

@charset "utf-8";

// Bulma variables:

$primary: #3da4a3;
$card-header-shadow: none;
$card-shadow: 0 0.3em 0.5em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);

$navbar-background-color: none;
$navbar-item-hover-color: #4a4a4a;

$tabs-toggle-link-radius: 0;
$tabs-toggle-link-border-color: #4a4a4a;

$input-disabled-background-color: #e6e6e6;

@import '../../../../node_modules/bulma/bulma.sass';

// Our own vars:

$deactivated-striped-gradient: repeating-linear-gradient(-45deg, #f5f5f5, #f5f5f5 10px, #f0f0f0 10px, #f0f0f0 20px);

$impounded-striped-gradient: repeating-linear-gradient(-45deg, #ffdd57, #ffdd57 10px, #ffdd577a 10px, #ffdd577a 20px);

// Bulma overrides and custom styles:

.modal-content,
.modal-card {
  width: auto;
}

.hide-on-mobile {
  @media screen and (max-width: 1024px) {
    display: none;
  }
}

@media screen and (max-width: 580px) {
  .tabs.wrap-on-mobile ul {
    flex-wrap: wrap;
    max-width: 100%;
    li {
      width: 50%;
    }
  }
}

html {
  padding-bottom: 5rem;
}

.newApp {
  z-index: 999;
  width: calc(100% + 2em);
  margin: -1em -1em 1em -1em;
  padding: 1em;
  background: #fabb2f;
  font-weight: bold;
  a {
    color: white;
  }
}

// Storybook helper:

.sb-show-main {
  margin: 1rem;
}

// Helpers and Modifiers
.mt1 {
  margin-top: 0.5em;
}

.mt2 {
  margin-top: 1em;
}

.mb1 {
  margin-bottom: 0.5em;
}

.mb2 {
  margin-bottom: 1em;
}

.ml1 {
  margin-left: 0.5em;
}

.ml2 {
  margin-left: 1em;
}

.no-wrap {
  white-space: nowrap;
}

.pos-rel {
  position: relative;
}

.flex-on-baseline {
  align-items: baseline;
}

.is-on-baseline {
  vertical-align: baseline;
}

.navbar {
  height: 3.25rem;
  position: relative;
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1024px) {
    .navbar-brand {
      flex-direction: column;
      font-size: 0.6rem;
      .header-customer {
        padding: 0.5em 0 0.15em 0;
        align-self: flex-start;
      }
      &.large-logo {
        font-size: 1rem;
      }
    }
  }
}

.navbar .button.active {
  box-shadow: $button-disabled-shadow;
  opacity: $button-disabled-opacity;
  pointer-events: none;
  cursor: default;
}

.navbar-menu {
  background: none;
  box-shadow: none;
  display: block;
}

.level.is-mobile.machine-selector {
  // make the machine-selector bar overlap the border in the header, and extend to the full page width
  margin: calc(-1em + -5px) -0.5em 1em;
  background-color: $primary;
  min-height: 40px;
  position: sticky;
  top: 0;
  z-index: 100;
  border-bottom: 3px solid white;
  & > .level-item {
    flex-shrink: 2;
    margin: 0;
    font-size: 0.9rem;
    line-height: 1.25;
  }
}

hr {
  &.prominent {
    background-color: $primary;
  }
  &.mini {
    margin: 0.5em 0;
  }
}

.container.is-fluid {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.vertical {
  display: flex;
  flex-direction: column;
}

.fixed-bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: #fff;
  padding: 3px;
  border-top: 1px solid #dbdbdb;
  &.tabs.is-fullwidth li:not(:last-child) {
    margin-right: 3px;
  }
}

.only-on-mobile {
  display: none;
  @media screen and (max-width: 1024px) {
    display: inherit;
  }
}

// Elements

.centered-flexbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-height: 200px;
  max-width: 280px;
  margin: 0 auto;
}

.app-header {
  // background: #00d1b2;
  // background: linear-gradient(104deg, #00d1b2 0%, #fdbb2d 100%);
  background: #dadada;
  background: linear-gradient(104deg, #fff 0%, #b0b0b0 100%);
  margin-bottom: 1rem;
  padding: 1rem;
  border-bottom: 5px solid $primary;
  .header-logo,
  .header-logo-small,
  .header-customer {
    font-weight: bold;
    color: $primary;
  }
  .header-logo {
    // font-size: 2rem;
    // letter-spacing: -1px;
    // font-style: italic;
    // margin-top: -1px;
    img {
      height: 3em;
    }
  }
  .header-logo-small {
    font-size: 0.75rem;
    font-style: italic;
  }
  .header-customer {
    font-size: 1.5em;
    line-height: 1;
    padding: 0.5em 0 0.15em 0.5em;
    align-self: flex-end;
  }
}

.qr-container {
  max-width: 500px;
  margin: 0 auto;
  .qr-scanner {
    width: 100%;
    section div {
      box-shadow: rgba(20, 231, 168, 0.5) 0px 0px 0px 5px inset !important;
    }
  }
}

.invalid-qr-code-overlay {
  position: absolute;
  top: 15%;
  left: 15%;
  width: 70%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  text-align: center;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 0px 3px black;
}

.machine-id {
  white-space: nowrap;
  font-family: Consolas, 'Courier New', Courier, monospace;
}

.well {
  background: #f5f5f5;
  margin: 0.5em 0;
  border-radius: 4px;
  .field {
    margin-bottom: 0;
  }
  &.columns {
    margin-bottom: 1rem;
  }
  &.has-padding {
    padding: 15px;
  }
  &.is-padded {
    padding: 0.5em;
  }
}

.info-list {
  h5 {
    font-size: 1.8rem;
    font-weight: bold;

    &.has-top-margin {
      margin-top: 20px;
    }
  }

  .label {
    font-size: 1.2rem;
    font-weight: bold;
    color: gray;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  strong {
    font-size: 1.2rem;
  }
}

.dashboard-machine-state {
  display: inline;
  border: 1px black solid;
  margin-right: 2px;
}

.storybook-label {
  background-color: #e0e0e0;
  font-family: monospace;
  color: #f00;
  padding: 0.25em;
  border-radius: 0.25em;
  margin-bottom: 1em;
  display: inline-block;
}

// Make a button the size of a tag
.tinyButtons button {
  font-size: 0.6em;
  margin-right: 0.25rem;
}

.machine.card {
  .card-header-title {
    display: flex;
    justify-content: space-between;
  }
  .machine-state {
    display: flex;
    align-items: center;
  }
  &.state-deactivated {
    background: $deactivated-striped-gradient;
  }
  &.state-impounded {
    background: $impounded-striped-gradient;
  }
  .tags {
    margin-top: 1em;
  }
}

// Making table rows linkable

*[data-href],
.is-row-link {
  cursor: pointer;
  &:hover {
    // Override bulma’s row stripiness
    background-color: $primary !important;
    color: #fff;
    strong {
      color: #fff;
    }
  }
}
.no-padding td {
  padding: 0;
}

td a:not(.button) {
  display: inline-block;
  min-height: 100%;
  width: 100%;
  padding: 5px 0;
  color: inherit;
}
td a.link {
  padding: 0;
  color: $primary;
}
td .disabled {
  color: tomato;
}
td {
  padding: 0;
  vertical-align: middle;
}

th .button {
  margin-left: -0.75em;
  padding: 0.75em;
}

.dashboard {
  .measurement-text-container textarea {
    min-height: 2.5em;
    padding: 0.5em;
  }
}

#__react-alert__ {
  & > div {
    .alert-message {
      margin: 15px 15px 0 0;
    }
  }
}

.user-table tbody tr {
  height: 57px;
  td {
    vertical-align: middle;
    min-height: 57px;
  }
}

tr.has-top-border td {
  border-top: 2px solid #333;
}

.flex-align-center {
  align-items: center;
}
.flex-justify-between {
  justify-content: space-between;
}
.flex-wrap {
  flex-wrap: wrap;
}
.machineHeaderContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.metricStatuses {
  display: inline-flex;
  background: #f2f2f2;
  border-radius: 1em;
  padding-top: 3em;
  margin-bottom: 0.5em;
  flex-wrap: wrap;
  position: relative;
  .collectionLabel {
    color: #4a4a4a;
    display: block;
    font-size: 1rem;
    font-weight: 700;
    position: absolute;
    top: 0.75em;
    left: 1em;
  }
  .metricStatus {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1em 1em 1em;
    &.high .display {
      background-color: #ffa500;
      color: #332100;
    }
    &.low .display {
      background-color: #b7e1fa;
      color: #0a4771;
    }
    &.good .display {
      background: #72c983;
      color: #112c16;
    }
    .display {
      background: #e0e0e0;
      border-radius: 2em;
      padding: 0.25em 1em;
      min-width: 2.5em;
      box-sizing: border-box;
      text-align: center;
      margin-bottom: 0.25em;
      display: flex;
      align-items: baseline;
      .value {
        font-size: 2em;
      }
      .unit {
        font-weight: bold;
      }
    }
  }
}

.options-list {
  max-width: 42em;
  .level-item > button {
    min-width: 6.5em;
  }
  .section-title {
    margin: 0.75em 0 0.5em 0;
  }
  .option {
    background: #f1f1f1;
    padding: 0.5em 0.5em 0.01em;
    margin-bottom: 0.25em;
    border-radius: 0.5em;
  }
}

.sub-settings {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  max-width: 42rem;
  border-radius: 0.5em;
  padding: 0 1em 0;
  margin: 0 0 0.5em 0;
  li {
    flex: 1 1 50%;
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
    button {
      margin-right: 0.5em;
    }
  }
}

// Checkbox styles

.checkbox label {
  display: flex;
  line-height: 1.75;
}

input[type='checkbox'] {
  -webkit-appearance: none;
  width: 1.75rem;
  height: 1.75rem;
  border: 1px solid #dbdbdb;
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
  border-radius: 4px;
  outline: none;
  margin-right: 1em;
}
input[type='checkbox']:focus {
  box-shadow: 0 0 0.3125rem 0.1125em rgba(0, 0, 0, 0.5);
}
input[type='checkbox']:checked {
  /* Prepare for placing the new checkmark. */
  position: relative;
  /* fix to keep iOS from adding dark background. */
  background: none;
}
input[type='checkbox']:checked::after {
  /* Place (position) the new checkmark. */
  position: absolute;
  top: 20%;
  left: 20%;
  content: '';
  width: 60%;
  height: 60%;
  // You can change the color of the check in the checkbox with the `stroke` and `fill` arguments, just
  // encode the `#` as `%23` for IE.
  background-image: url('data:image/svg+xml;utf8,<svg height="24" width="24" xmlns="http://www.w3.org/2000/svg"><path stroke="%23363636" fill="%23363636" clip-rule="evenodd" d="M21.652 3.211c-.293-.295-.77-.295-1.061 0L9.41 14.34c-.293.297-.771.297-1.062 0L3.449 9.351a.7372.7372 0 0 0-.526-.222.7433.7433 0 0 0-.536.222L.222 11.297c-.144.148-.222.333-.222.526 0 .194.078.397.223.544l4.94 5.184c.292.296.771.776 1.062 1.07l2.124 2.141c.292.293.769.293 1.062 0l14.366-14.34c.293-.294.293-.777 0-1.071l-2.125-2.14z" fill-rule="evenodd"/></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.field,
.notification {
  max-width: 42em;
}

textarea.input {
  height: auto;
}

.userInvitationForm {
  .deleteButton {
    @media screen and (min-width: 768px) {
      margin-top: 32px;
    }
  }

  .addButton {
    padding-left: 0;
    padding-bottom: 30px;
  }

  .messageBody {
    min-height: 200px;
    height: auto;
    background-color: whitesmoke;
    color: #7a7a7a;
    white-space: pre-wrap;
    max-width: 42em;
    p {
      padding: calc(0.75em - 1px);
    }
  }
}

.usablesWrapper {
  .deleteButton {
    display: flex;
    align-items: flex-end;
  }
}

.concentration-operator {
  text-align: center;
  margin-top: 0;
  flex: none;
  width: 100%;
  font-weight: bold;
  padding: 0;
}

@media screen and (min-width: 768px) {
  .concentration-operator {
    margin-top: 3rem;
    flex: none;
    width: 2rem;
    padding: initial;
  }
}
@media screen and (min-width: 512px) {
  .is-concentration input {
    min-width: 17em;
  }
}

